import * as React from 'react'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'
import './styles.css'
dayjs.locale('pt-br')
export const Date: React.FC<{ name: string, setState: any, state: any, id: string, label: string }> = ({ ...props }) => {
  const { name, setState, state, label } = props
  return (
    <div>
      <label style={{ fontSize: 16, fontWeight: 'bold', color: '#332E1A', margin: '0px' }}>{label}</label>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='pt-BR' >
        <DemoContainer components={['DatePicker']} sx={{ width: '300px', height: '50px', backgroundColor: '#EFEFEF', borderRadius: '5px', margin: '0px', overflow: 'hidden', top: '-9px' }}>
          <DatePicker
            localeText={{ start: 'Data', end: 'Data' }}
            sx={{ width: '300px', height: '50px', backgroundColor: '#EFEFEF', borderRadius: '5px'}}
            value={state[name] || ''}
            slotProps={{
              inputAdornment: {
                position: 'end',
                sx: {
                  paddingRight: '20px'
                },
              },
            }}
            onChange={(value) => setState({ ...state, [`${name}`]: value })}
            format='DD/MM/YYYY'
            
          />
        </DemoContainer>
      </LocalizationProvider>
    </div>
  )
}