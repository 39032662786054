import axios from 'axios'

if (process.env.NODE_ENV === 'development') {
  axios.defaults.baseURL = 'http://localhost:1338/api'
} else {
  axios.defaults.baseURL = 'https://sistema.cachacagestor.com.br/api'
}

const api = axios.create()
const currentUser = JSON.parse(localStorage.getItem('currentUser') as string)
if (window.location.pathname !== '/' && currentUser !== null) {
  api.defaults.headers.common['sessionToken'] = currentUser.sessionToken
}

export default api