import { Buttom } from 'components/buttom/buttom'
import { useEffect, useState } from 'react'
import { getContingency, updateContingency } from 'services/Contingency/Contingency'
import { MultiSelect } from 'react-multi-select-component'
import { Loading } from 'components/loading/loading'

export const Contingency = () => {
  const [loading, setLoading] = useState(false)
  const [contingency, setContingency] = useState([])
  const [selectedStates, setSelectedStates] = useState([])
  const options = [
    { label: 'AC', value: 'AC' },
    { label: 'AL', value: 'AL' },
    { label: 'AP', value: 'AP' },
    { label: 'AM', value: 'AM' },
    { label: 'BA', value: 'BA' },
    { label: 'CE', value: 'CE' },
    { label: 'ES', value: 'ES' },
    { label: 'GO', value: 'GO' },
    { label: 'MA', value: 'MA' },
    { label: 'MT', value: 'MT' },
    { label: 'MS', value: 'MS' },
    { label: 'MG', value: 'MG' },
    { label: 'PA', value: 'PA' },
    { label: 'PB', value: 'PB' },
    { label: 'PR', value: 'PR' },
    { label: 'PE', value: 'PE' },
    { label: 'PI', value: 'PI' },
    { label: 'RJ', value: 'RJ' },
    { label: 'RN', value: 'RN' },
    { label: 'RS', value: 'RS' },
    { label: 'RO', value: 'RO' },
    { label: 'RR', value: 'RR' },
    { label: 'SC', value: 'SC' },
    { label: 'SP', value: 'SP' },
    { label: 'SE', value: 'SE' },
    { label: 'TO', value: 'TO' },
    { label: 'DF', value: 'DF' }
  ]

  const handleContingency = async () => {
    setLoading(true)
    try {
      const response = await getContingency({})
      setLoading(false)
      setContingency(response)
    } catch (error: any) {
      alert(error.response.data.message || 'Algum erro aconteceu!')
    }
  }


  const handleUpdateContingency = async (selectedStates: any) => {
    try {
      setLoading(true)
      const stateValues = selectedStates.map((state: { value: string }) => state.value)
      const response = await updateContingency(stateValues)
      const showStates = response.length === 0 ? 'Nenhum' : response.join(', ')
      alert('Estados em contingência atualizados: ' + showStates)
      await handleContingency()
    } catch (error: any) {
      alert(error.response.data.message || 'Algum erro aconteceu!')
    }
  }

  useEffect(() => {
    handleContingency()
  }, [])

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='container mt-4'>
          <div className='row justify-content-center'>
            <div className='col-12 text-center'>
              <h1>Estados em Contingência</h1>
            </div>
          </div>
          <div className='row justify-content-center my-3'>
            <div className='col-auto text-center'>
              <strong>Estados em Contingência:&nbsp;</strong>
              <span id="contingency_values">
                {contingency.length > 0 ? contingency.join(', ') : 'Nenhum'}
              </span>
            </div>
          </div>
          <div className='row justify-content-center my-3'>
            <div className='col-auto'>
              <div className='col-12 text-center'>
                <h3>Incluir estados em contingência:</h3>
              </div>
              <MultiSelect
                className='multiselect_contingency'
                options={options}
                value={selectedStates}
                onChange={setSelectedStates}
                labelledBy='Select'
              />
            </div>
          </div>
          <div className='row justify-content-center my-3'>
            <div className='col-auto'>
              <Buttom text='Aplicar' id='btn-add' onClick={() => { handleUpdateContingency(selectedStates) }} disabled={false} />
            </div>
          </div>
        </div>
      )
      }
    </>
  )
}