import { useEffect, useState } from 'react'
import { Text } from '../../../../components/inputs/text/Text'
import { SelectField } from '../../../../components/inputs/select/Select'
import { Date } from '../../../../components/inputs/date/Date'
import { Checkbox } from '../../../../components/inputs/checkbox/Checkbox'
import { ButtomWithIcon } from '../../../../components/buttom/buttomWithIcon'
import { IoMdArrowRoundBack } from 'react-icons/io'
import { Buttom } from '../../../../components/buttom/buttom'
import { createSignature } from '../../../../services/Signature/Signature'
import { Loading } from 'components/loading/loading'

export const CreateSignature: React.FC<{ companySelected: any, setSubscriptionMenu: any, setCreateSubscription: any }> = ({ ...props }) => {
  const { companySelected, setSubscriptionMenu, setCreateSubscription } = props
  const [state, setState] = useState({
    fabrica: companySelected,
    valor: 0,
    formaPagamento: 1,
    cobrado: false,
    concluido: false,
    dataVencimento: ''
  })

  const [loading, setLoading] = useState(false)
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)

  useEffect(() => {
    if (state.dataVencimento)
      setIsButtonDisabled(!state.dataVencimento)

    if (state.formaPagamento === 1) {
      setState(oldState => ({
        ...oldState,
        cobrado: false
      }))
    }
    else {
      setState(oldState => ({
        ...oldState,
        concluido: false
      }))
    }

  }, [state.dataVencimento, state.formaPagamento])


  const handleCreateSubscription = async () => {
    try {
      setLoading(true)
      const newState = {
        ...state,
        valor: Number(String(state.valor).replace('.', '').replace(',', '.'))
      }
      await createSignature(newState)
      setCreateSubscription(false)
      alert('Assinatura criada com sucesso!')
      setSubscriptionMenu(true)
    } catch (error: any) { alert(error.response.data.message || 'Algum erro aconteceu!') }
    finally {
      setLoading(false)
    }
  }

  return (
    <>
      <div className='row'>
        <div className='col-12'>
          <h1 className='text-center'>Criar uma nova assinatura</h1>
        </div>
      </div>
      <div className='row justify-content-center'>
        <div className='col-md-4 col-sm-12 d-flex justify-content-center p-3'>
          <Text type={'money'} name={'valor'} id='input-text-signature' label="Qual o valor do plano?" required={true} state={state} setState={setState} />
        </div>
        <div className='col-md-4 col-sm-12 d-flex justify-content-center p-3'>
          <SelectField name={'formaPagamento'} setState={setState} state={state} id={'input-select-payment'} label={'Forma de pagamento'} options={[{ label: 'Boleto', value: 1 }, { label: 'Cartão de Crédito', value: 2 }]} />
        </div>
        <div className='col-md-4 col-sm-12 d-flex justify-content-center p-3'>
          <Date name={'dataVencimento'} setState={setState} state={state} id={'input-date-payment'} label={'Data de Vencimento'} />
        </div>
      </div>
      <div className='row '>
        <div className='col-12 mt-3  d-flex justify-content-center p-3'>
          {state['formaPagamento'] === 1 && <Checkbox name={'concluido'} setState={setState} state={state} id={'finished'} label={'O pagamento já foi concluído?'} />}
          {state['formaPagamento'] === 2 && <Checkbox name={'cobrado'} setState={setState} state={state} id={'paid'} label={'Já esta sendo cobrado na Efí?'} />}
        </div>
      </div>
      <div className='row'>
        <div className='col'></div>
        <div className='col-12 mt-3 justify-content-center d-flex'>
          {loading ? (
            <Loading />
          ) : (
            <Buttom text="Criar" onClick={handleCreateSubscription} id={'button-select-new-subscription'} disabled={isButtonDisabled || state.valor <= 0} color='#30A151' />
          )
          }
        </div>
      </div>
      <div className='row'>
        <div className='col'></div>
        <div className='col-12 mt-3 justify-content-center d-flex' onClick={() => { setCreateSubscription(false); setSubscriptionMenu(true); setIsButtonDisabled(true) }}>
          <ButtomWithIcon text="Selecionar outra opção" id={'button-select-new-subscription'} disabled={false}
            icon={<IoMdArrowRoundBack size="20" style={{ color: 'white', cursor: 'pointer' }} />} />
        </div>
      </div>
    </>
  )
}