import React, { useEffect, useState } from 'react'
import { getCompanies, getSummary, cancelSignature } from '../../../../services/Signature/Signature'
import './styles.css'
import { Buttom } from '../../../../components/buttom/buttom'
import { Loading } from '../../../../components/loading/loading'
import { ButtomWithIcon } from 'components/buttom/buttomWithIcon'
import { IoMdArrowRoundBack } from 'react-icons/io'

export const CancelSignature: React.FC<{ companySelected: any, setCancelSignature: any, setSubscriptionMenu: any, setViewSubscription: any, setCompanySelected: any }> = ({ ...props }) => {
  const { companySelected, setSubscriptionMenu, setViewSubscription, setCompanySelected, setCancelSignature } = props
  const [loading, setLoading] = useState(true)
  const [summary, setSummary] = useState<any>([])
  const [signatureSelected, setSignatureSelected] = useState<any>({})
  const [isButtonDisabled, setButtonDisabled] = useState(true)
  const [openSelectToChoice, setOpenSelectToChoice] = useState(false)
  const [loadingButton, setLoadingButton] = useState(false)
  useEffect(() => {
    const handleSummary = async () => {
      try {
        setLoading(true)
        const response = await getSummary(companySelected)
        if (response.length === 0) {
          setSummary([{
            nome: companySelected.empresa.nome,
            plano: companySelected.plano.nome,
            proprietario: companySelected.proprietario.nome,
            formaPagamento: '',
            parcelas: []
          }])
        } else {
          setSummary(response)
        }
        setLoading(false)
      } catch (error: any) {
        alert(error.response.data.message || 'Algum erro aconteceu!')
      }
    }

    handleSummary()
  }, [])

  const handleCancelSignature = async () => {
    setLoadingButton(true)
    const request = {
      fabrica: companySelected,
      assinatura: signatureSelected
    }

    try {
      const response = await cancelSignature(request)
      alert(response.message)
      const companyUpdated = await getCompanies({ nome: companySelected.empresa.nome })
      setSignatureSelected(signatureSelected)
      setOpenSelectToChoice(true)
      setCompanySelected(companyUpdated[0])
      setCancelSignature(false)
      setSubscriptionMenu(true)
    } catch (error: any) {
      alert(error.response.data.message || 'Algum erro aconteceu!')
    }

    setLoadingButton(false)
  }

  const statusOfPayment = (installment: any) => {
    const currentDate = new Date()
    if (installment.status === true) {
      return 'PAGO'
    } else if (installment.status === false && currentDate > new Date(installment.data)) {
      return 'INADIMPLENTE'
    } else {
      return 'AGUARDANDO'
    }
  }

  return (
    <>
      {loading ? <Loading /> :
        openSelectToChoice == false && <>
          {summary.length > 0 && <div className='col-12'>
            <h1 className='text-center'>Selecione uma assinatura para canceçar </h1>
          </div>}
          <div className='row'>
            {summary.map((summaryItem: any, index: number) => (
              <div className='col-12' key={index}>
                <div className='subscription-info'>
                  <div className="form-check my-1">
                    <input
                      className="form-check-input"
                      type="radio"
                      name={`radioSummary-${index}`}
                      id={`radioSummary-${index}`}
                      onChange={() => { setButtonDisabled(false); setSignatureSelected(summaryItem) }}
                    />
                    <label className="form-check-label" htmlFor={`radioSummary-${index}`}>
                      Selecionar Assinatura
                    </label>
                  </div>
                  <p><strong>Nome:</strong> {summaryItem.nome}</p>
                  <p><strong>Plano:</strong> {summaryItem.plano}</p>
                  <p><strong>Proprietário:</strong> {summaryItem.proprietario}</p>
                  <p><strong>Forma de Pagamento:</strong> {summaryItem.formaPagamento}</p>
                </div>
                {summaryItem.parcelas.length > 0 ? (
                  <div className='col-12'>
                    <table className='table'>
                      <thead>
                        <tr>
                          <th scope="col">Data</th>
                          <th scope="col">Parcela</th>
                          <th scope="col">Valor</th>
                          <th scope="col">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {summaryItem.parcelas.map((installment: any, index: any) => (
                          <tr id={`installment_${index}`} key={index}>
                            <td>{new Date(installment.data).toLocaleDateString('pt-BR')}</td>
                            <td>{installment.parcela}</td>
                            <td>{`R$ ${installment.valor.toFixed(2).replace('.', ',')}`}</td>
                            <td className={`${installment.status ? 'pago' : 'aguardando'}`}>
                              {statusOfPayment(installment) === 'INADIMPLENTE' ?
                                (<span style={{ color: 'red' }}>INADIMPLENTE</span>)
                                : (statusOfPayment(installment))}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div className='col-12'>
                    <p className='text-center h3' id='summary-no-installments'>Nenhuma parcela encontrada</p>
                  </div>
                )}
              </div>
            ))}
            <div className='col-12 mt-3 justify-content-center d-flex'>
              <Buttom text="Cancelar" id='btn-cancel-signature' onClick={handleCancelSignature} disabled={isButtonDisabled} loadingButton={loadingButton} />
            </div>
            <div className='col-12 mt-5 justify-content-center d-flex'>
              <ButtomWithIcon
                text="Selecionar outra opção"
                onClick={() => { setCancelSignature(false); setViewSubscription(false); setSubscriptionMenu(true) }}
                id="button-select-new-subscription"
                disabled={false}
                icon={<IoMdArrowRoundBack size="20" style={{ color: 'white', cursor: 'pointer' }} />}
              />
            </div>
          </div>
        </>
      }
    </>

  )

}