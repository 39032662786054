import { useState } from 'react'
import { TextWithSearch } from '../../components/inputs/text/TextWithSearch'
import { Buttom } from '../../components/buttom/buttom'
import { Loading } from '../../components/loading/loading'
import { SubscriptionMenu } from './SubscriptionControl/SubscriptionMenu'
import { getCompanies } from '../../services/Signature/Signature'

export const Signature = () => {
  const [showOptions, setShowCompanies] = useState<boolean>(false)
  const [companies, setCompanies] = useState<object[]>([])
  const [companySelected, setCompanySelected] = useState<object>({})
  const [isButtonDisabled, setButtonDisabled] = useState(true)
  const [loading, setLoading] = useState(false)
  const [subscriptionControl, setSubscriptionControl] = useState(false)
  const [state, setState] = useState({ nome: '' })

  const handleSearch = async () => {

    setLoading(true)
    try {
      setButtonDisabled(true)
      const response = await getCompanies({ nome: state['nome'] })
      setShowCompanies(true)
      setCompanies(response)
    } catch (error: any) { alert( error.response.data.message || 'Algum erro aconteceu!') }
    setLoading(false)
  }

  return (
    <>
      {!subscriptionControl &&
        <div >
          <div className='justify-content-center d-flex'>
            <TextWithSearch id='input-text-signature' type="text" label="Qual fábrica?" required={true} name="nome" state={state} setState={setState} handleSearch={handleSearch} />
          </div>
          {loading ? <Loading /> :
            <>
              <div className="justify-content-center" style={{ overflow: 'hidden' }}>
                {

                  showOptions &&
                  (companies.map((item: any, index: number) => (
                    <div key={`key-div-${index}`} className="form-check">
                      <input
                        key={`key-input-${index}`}
                        className="form-check-input text-primary"
                        type="radio"
                        name="options-inline"
                        id={`option-${index}`}
                        value={`option-${index}`}
                        onChange={() => { setButtonDisabled(false); setCompanySelected(item) }}
                      />
                      <label key={`key-label-${index}`} className="form-check-label" htmlFor={`option-${index}`}>
                        <strong>Nome: </strong>{item.empresa.nome} - <strong>Data de Criação: </strong>{new Date(item.empresa.createdAt).toLocaleDateString('pt-BR')}
                      </label> <br />
                    </div>

                  )))}
              </div>

              <br />
              {showOptions &&
                <div className="row justify-content-center my-">
                  <Buttom text="Selecionar" id='btn-select' onClick={() => { setShowCompanies(false); setSubscriptionControl(true) }} disabled={isButtonDisabled} />
                </div>
              }</>}
        </div >
      }
      {subscriptionControl && <SubscriptionMenu companySelected={companySelected} setCompanySelected={setCompanySelected} setSubscriptionControl={setSubscriptionControl} />}
    </>
  )
}