import { Link, useNavigate } from 'react-router-dom'
import { IoMdExit } from 'react-icons/io'

import logo from '../../assets/logo.png'
import './styles.css'
import { styles } from '../styles'
export const Navbar: React.FC = () => {
  const navigate = useNavigate()
  const handleLogout = () => {
    localStorage.removeItem('currentUser')
    navigate('/')
  }
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light p-0">
      <img src={logo} alt="logo" style={{ width: '70px', marginRight: '10px' }} />
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
        <div className="navbar-nav">
          <div className='row '>
            <div className='col-sm-12 col-md p-0'>
              <Link id="nav-link-dashboard ml-auto" to="/dashboard" style={{ textDecoration: 'none' }}>
                <div className='nav-item'
                  style={{
                    backgroundColor: window.location.pathname === '/dashboard' ? '#FFFFFF' : '',
                    textDecoration: 'none',
                    borderBottom: window.location.pathname === '/dashboard' ? '2px solid white' : 'none'
                  }}>
                  <span style={{ color: window.location.pathname === '/dashboard' ? styles.color.primary : 'white' }}
                  >Início</span>
                </div>
              </Link>
            </div>
            <div className='col-sm-12 col-md p-0'>
              <Link id="nav-link-signature" to="/assinaturas" style={{ textDecoration: 'none' }}>
                <div className='col-sm-12 col-md nav-item'
                  style={{
                    backgroundColor: window.location.pathname === '/assinaturas' ? '#FFFFFF' : '',
                    textDecoration: 'none',
                    borderBottom: window.location.pathname === '/assinaturas' ? '2px solid white' : 'none'
                  }}>
                  <span style={{ color: window.location.pathname === '/assinaturas' ? styles.color.primary : 'white' }}
                  >Assinaturas</span>
                </div>
              </Link>
            </div>
            {/* <div className='col-sm-12 col-md p-0'>
              <Link id="nav-link-report" to="/relatorios" style={{ textDecoration: 'none' }}>
                <div className='col-sm-12 col-md nav-item' style={{
                  backgroundColor: window.location.pathname === '/relatorios' ? '#FFFFFF' : '',
                  textDecoration: 'none',
                  borderBottom: window.location.pathname === '/relatorios' ? '2px solid white' : 'none'
                }}>
                  <span style={{ color: window.location.pathname === '/relatorios' ? styles.color.primary : 'white' }}
                  >Relatórios</span>
                </div>
              </Link>
            </div> */}
            <div className='col-sm-12 col-md p-0'>
              <Link id="nav-link-super-user" to="/super-usuario" style={{ textDecoration: 'none' }}>
                <div className='col-sm-12 col-md nav-item' style={{
                  backgroundColor: window.location.pathname === '/super-usuario' ? '#FFFFFF' : '',
                  textDecoration: 'none',
                  borderBottom: window.location.pathname === '/super-usuario' ? '2px solid white' : 'none'
                }}>
                  <span style={{ color: window.location.pathname === '/super-usuario' ? styles.color.primary : 'white' }}
                  >Super Usuário</span>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className="navbar-nav ml-auto" onClick={handleLogout}>
          <div className='nav-item'>
            <IoMdExit size="25" style={{ color: 'white', cursor: 'pointer' }} />
            <span style={{ color: 'white', cursor: 'pointer', marginLeft: '15px' }}>Sair</span>
          </div>
        </div>
      </div>
    </nav >
  )
}