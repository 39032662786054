import * as React from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export const PageNotFound404 = () => {
  const navigate = useNavigate()
  useEffect(() => {
    setTimeout(() => {
      navigate('/')
    }, 5000)
  })
  return (
    <div className="align-items-center justify-content-center">
      <div className="row justify-content-center">
        <div className="col-md-12 text-center">
          <span className="display-1 d-block">404</span>
          <div className="mb-4 lead">A página {window.location.href} não foi encontrada!</div>
          <div className="mb-4 lead">Você será redirecionado em 5 segundos para a página inicial
            <div className="d-flex justify-content-center">

              <div className="spinner-grow" role="status" style={{ width: '10px', height: '10px' }}> </div>
              <div className="spinner-grow" role="status" style={{ width: '10px', height: '10px' }}> </div>
              <div className="spinner-grow" role="status" style={{ width: '10px', height: '10px' }}> </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}