import { useNavigate } from 'react-router-dom'
import { Buttom } from '../../components/buttom/buttom'

export const Dashboard = () => {
  const navigate = useNavigate()
  return (
    <>
      <div className="row m-4">
        <h4 id="dashboard-title">Bem vindo ao sistema de gerenciamento</h4>
      </div>
        <div className="row m-3">
            <div className="col-sm-4 mb-2">
                <Buttom text="Ir para Assinaturas" onClick={() => navigate('/assinaturas')} id="nav-link-signature"/>
            </div>
            {/* <div className="col-sm-4 mb-2">
          <Buttom text="Ir para Relatórios" onClick={() => navigate('/relatorios')} id="nav-link-report" />
        </div> */}
            <div className="col-sm-4 mb-2">
                <Buttom text="Ir para Super Usuários" onClick={() => navigate('/super-usuario')}
                        id="nav-link-super-user"/>

            </div>
            <div className="col-sm-4 mb-2">
                <Buttom text="Estados em Contingência" onClick={() => navigate('/contingencia')}
                        id="nav-link-contingency"/>
            </div>
        </div>
    </>
  )
}