import React from 'react'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import { IconButton, InputAdornment, TextField } from '@mui/material'
import { useState } from 'react'
import './styles.css'
import { Mask } from '../../../utils/mask'
export const Text: React.FC<{ onKeyUp?: () => void, required: boolean, label: string, type: string, id: string, setState?: any, state: any, name: string }> = ({ ...props }) => {
  const { required, label, type, id, setState, state, name, onKeyUp } = props
  const [showPassword, setShowPassword] = useState(type === 'password' ? false : true)
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }
  const handleCurrencyChange = (value: string | number) => {
    const mask = new Mask()
    setState({ ...state, [name]: mask.money(value) })
  }

  return (
    <>
      <div>
        <label style={{ fontSize: 16, fontWeight: 'bold', color: '#332E1A', margin: '0px' }}>{label}</label>
        <br />
        {type !== 'money' ? <TextField
          size='medium'
          onKeyUp={onKeyUp && ((e) => e.key === 'Enter' ? onKeyUp() : void 0)}
          type={showPassword ? 'text' : 'password'}
          style={{ width: '300px', backgroundColor: '#EFEFEF', borderRadius: '5px' }}
          value={state[name]}
          onChange={event => setState({ ...state, [`${name}`]: event.target.value })}
          id={id}
          required={required}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                {type === 'password' && <IconButton
                  onClick={handleClickShowPassword}
                  edge="start"
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </IconButton>}
              </InputAdornment>
            ),
          }}
          fullWidth />
          :
          <TextField
            className='input'
            id={`${id}`}
            name={name}
            value={state[name]}
            onChange={(e) => handleCurrencyChange(e.target.value)}
            InputProps={{
              startAdornment: (
                <div style={{ fontWeight: 'bold', color: '#555555', backgroundColor: '#EcE7E7', borderRadius: '5px 0px 0px 5px ', padding: '12px', borderRight: '1px solid', borderColor: '#332E1A', position: 'relative', right: '14px' }}>
                  <i>R$</i>
                </div>
              ),
            }}
          />}
      </div></>
  )
}
