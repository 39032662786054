import React, { useEffect } from 'react'
import { IoMdArrowRoundBack } from 'react-icons/io'
import { ButtomWithIcon } from '../../../../components/buttom/buttomWithIcon'
import { getSummary } from '../../../../services/Signature/Signature'
import './styles.css'
import { Loading } from '../../../../components/loading/loading'
import { TableSignature } from 'components/table/TableSignature'

export const SubscriptionSummary: React.FC<{ companySelected: any, setViewSubscription: any, setSubscriptionMenu: any }> = ({ ...props }) => {
  const { companySelected, setViewSubscription, setSubscriptionMenu } = props
  const [loading, setLoading] = React.useState(true)
  const [summary, setSummary] = React.useState<any>([])

  useEffect(() => {
    const handleSummary = async () => {
      try {
        setLoading(true)
        const response = await getSummary(companySelected)
        if (response.length === 0) {
          setSummary([{
            nome: companySelected.empresa.nome,
            plano: companySelected.plano.nome,
            proprietario: companySelected.proprietario.nome,
            formaPagamento: '',
            parcelas: []
          }])
        } else {
          setSummary(response)
        }
        setLoading(false)
      } catch (error: any) {
        alert(error.response.data.message || 'Algum erro aconteceu!')
      }
    }
    handleSummary()
  }, [])

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className='row'>
            <div className='col-12'>
              <h1 className='text-center'>Resumo da assinatura</h1>
            </div>
            <TableSignature summary={summary} useCheckBox={{ view: false }} viewInvoice={true} companySelected={companySelected} />
          </div>

          <div className='col-12 mt-3 justify-content-center d-flex'>
            <ButtomWithIcon
              text="Selecionar outra opção"
              onClick={() => { setViewSubscription(false); setSubscriptionMenu(true) }}
              id="button-select-new-subscription"
              disabled={false}
              icon={<IoMdArrowRoundBack size="20" style={{ color: 'white', cursor: 'pointer' }} />}
            />
          </div>
        </>
      )}
    </>
  )
}