import * as React from 'react'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'

export const SelectField: React.FC<{ label: string, options: any, id: string, setState: any, state: any, name: string }> = ({ ...props }) => {
  const { label, options, id, setState, state, name } = props
  return (
    <div>
      <label style={{ fontSize: 16, fontWeight: 'bold', color: '#332E1A', margin: '0px' }}>{label}</label>
      <br />
      <Select
        id={`input-select-${id}`}
        style={{ width: '300px', height: '50px', backgroundColor: '#EFEFEF', borderRadius: '5px', margin: '0px' }}
        value={state[name] || ''}
        onChange={(e) => { setState({ ...state, [`${name}`]: e.target.value }) }}
      >
        {options.map((option: any, index: number) => (
          <MenuItem key={option.value} value={option.value} id={`option-${index}`}>{option.label}</MenuItem>
        ))}
      </Select>
    </div>
  )
}