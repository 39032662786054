import { login } from '../../services/Login/login'
export const handleLogin = async (state: any) => {
  const { username, password } = state

  const REACT_APP_PASSWORD_DEVTESTE = process.env.REACT_APP_PASSWORD_DEVTESTE
  const REACT_APP_PASSWORD_ADMIN = process.env.REACT_APP_PASSWORD_ADMIN
  const REACT_APP_PASSWORD_SUPPORT = process.env.REACT_APP_PASSWORD_SUPPORT

  

  if ((username !== 'adminproprietario' && password !== REACT_APP_PASSWORD_ADMIN) &&
    (username !== 'adminsuporte' && password !== REACT_APP_PASSWORD_SUPPORT) &&
    (username !== 'admindevteste' && password !== REACT_APP_PASSWORD_DEVTESTE)) {
    // eslint-disable-next-line no-throw-literal
    throw {
      response: {
        data: {
          message: 'Usuário não encontrado'
        }
      }
    }
  }
  return await login(username, password)
}

