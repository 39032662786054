import React from 'react'
import './styles.css'

export const Checkbox: React.FC<{ name: string, setState: any, state: any, id: string, label: string }> = ({ ...props }) => {
  const { name, setState, state, id, label } = props

  return (
    <div>
      <label id="labelText" style={{ fontSize: 16, fontWeight: 'bold', color: '#332E1A', margin: '0px' }}>{label}</label>
      <input
        type="checkbox"
        className={`checkbox ${state[name] ? 'text-success' : 'text-danger'}`}
        id={`input-checkbox-${id}`}
        checked={state[name] || false}
        onChange={(e) => setState({ ...state, [name]: e.target.checked })}
      />
      <label id="confirm" style={{ fontSize: 16, fontWeight: 'bold', color: state[name] ? '#30A151' : '#FA2B62' }} >{state[name] ? 'SIM' : 'NÃO'}</label>
    </div>
  )
}
