import * as React from 'react'
import { Text } from '../../components/inputs/text/Text'
import logo from '../../assets/logo.png'
import { Buttom } from '../../components/buttom/buttom'
import { useNavigate } from 'react-router-dom'
import { handleLogin } from './hooks'
import { useState } from 'react'
export const Login = () => {
  const navigate = useNavigate()
  const [state, setState] = useState({ username: '', password: '' })
  const [error, setError] = useState()
  const [loadingButton, setLoadingButton] = useState(false)

  const handleLoginClick = async () => {
    try {
      setLoadingButton(true)
      await handleLogin(state)
      navigate('/dashboard')
      setLoadingButton(false)
    } catch (error: any) {
      setError(error.response.data.message)
      setLoadingButton(false)

    }
  }

  return (
    <div style={{ top: '10%' }}>
      <div className="row justify-content-center m-3">
        <img src={logo} alt="logo" />
      </div>
      <div className="row justify-content-center m-3">
        <span className='text-secondary' style={{ fontWeight: 'bold' }}>Acesse o Sistema Administrador </span>
      </div>

      <div className="row justify-content-center m-3">
        <div className="col-auto">
          <Text label="Usuário" name="username" id="input-text-user" type="text" required={true} setState={setState} state={state} />
        </div>
      </div>
      <div className="row justify-content-center m-3">
        <div className="col-auto">
          <Text onKeyUp={handleLoginClick}
            label="Senha" id="input-text-password" type="password" required={true} setState={setState} state={state} name="password" />
        </div>
      </div>
      {error && <div className='row justify-content-center text-danger' >
        <span style={{ fontSize: '12px', fontWeight: 'bold' }}>{error}</span>
      </div>}
      <div className="row justify-content-center m-3">
        <Buttom text="Entrar" id='btn-login' loadingButton={loadingButton} disabled={loadingButton} onClick={handleLoginClick} />
      </div>
    </div >
  )
}