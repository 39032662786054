import { FaSearch } from 'react-icons/fa'

import { IconButton, InputAdornment, TextField } from '@mui/material'
import './styles.css'
export const TextWithSearch: React.FC<{ required: boolean, label: string, type: string, id: string, name: string, state: any, setState: any, handleSearch: () => void }> = ({ ...props }) => {
  const { required, label, id, handleSearch, state, setState, name } = props

  return (
    <>
      <div>
        <label style={{ fontSize: 16, fontWeight: 'bold', color: '#332E1A' }}>{label}</label>
        <br />
        <TextField
          onKeyUp={e => e.key === 'Enter' ? handleSearch() : void 0}
          size='medium'
          type={'text'}
          style={{ width: '300px', backgroundColor: 'white', borderRadius: '5px' }}
          InputLabelProps={{
            style: {
              fontSize: 16
            }
          }}
          value={state.name}
          onChange={event => setState({ ...state, [name]: event.target.value })}
          id={id}
          required={required}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  id='btn-search'
                  style={{ backgroundColor: '#30A151', borderRadius: '0px 5px 5px 0px', margin: '0px' }}
                  onClick={handleSearch}
                  edge="end"
                >
                  <FaSearch color='white' size={33} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          fullWidth
        />
      </div>

    </>

  )
}
