import { useEffect, useState } from 'react'
import { Text } from '../../../../components/inputs/text/Text'
import { ButtomWithIcon } from '../../../../components/buttom/buttomWithIcon'
import { IoMdArrowRoundBack } from 'react-icons/io'
import { Buttom } from '../../../../components/buttom/buttom'
import { updateDueDateOfSignature } from '../../../../services/Signature/Signature'
import { Loading } from 'components/loading/loading'

export const UpdateDueDate: React.FC<{ companySelected: any, setSubscriptionMenu: any, setUpdateDueDate: any }> = ({ ...props }) => {
  const { companySelected, setSubscriptionMenu, setUpdateDueDate } = props
  const [date, setDate] = useState<any>({})
  const [extractedDate, setExtractedDate] = useState<string | number | null>(null)
  const [loading, setLoading] = useState(false)
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)

  useEffect(() => {
    if (typeof date === 'object' && date !== null && Object.keys(date).length > 0) {
      const firstKey = Object.keys(date)[0] 
      const firstValue = date[firstKey] 
      setExtractedDate(firstValue) 
    }
  }, [date])

  const handleCreateSubscription = async () => {
    try {
      setLoading(true)
      if (extractedDate !== null) {
        const newDate = String(extractedDate)
        await updateDueDateOfSignature(newDate, companySelected)
        setUpdateDueDate(false)
        alert(`Data de vencimento atualizada para os dias ${newDate}!`)
        setSubscriptionMenu(true)
      }
    } catch (error: any) { alert(error.response.data.message || 'Algum erro aconteceu!') }
    finally {
      setLoading(false)
    }
  }

  return (
    <>
      <div className='row'>
        <div className='col-12'>
          <h1 className='text-center'>Alterar data de vencimento</h1>
        </div>
      </div>
      <div className='row justify-content-center'>
        <div className='col-md-4 col-sm-12 d-flex justify-content-center p-3'>
          <Text type={'text'} name={'valor'} id='input-text-signature' label="Dia do novo vencimento" required={true} state={date} setState={setDate} />
        </div>
      </div>
      <div className='row'>
        <div className='col'></div>
        <div className='col-12 mt-3 justify-content-center d-flex'>
          {loading ? (
            <Loading />
          ) : (
            <Buttom text="Criar" onClick={handleCreateSubscription} id={'button-select-new-subscription'} disabled={isButtonDisabled} color='#30A151' />
          )
          }
        </div>
      </div>
      <div className='row'>
        <div className='col'></div>
        <div className='col-12 mt-3 justify-content-center d-flex' onClick={() => { setUpdateDueDate(false); setSubscriptionMenu(true); setIsButtonDisabled(true) }}>
          <ButtomWithIcon text="Selecionar outra opção" id={'button-select-new-subscription'} disabled={false}
            icon={<IoMdArrowRoundBack size="20" style={{ color: 'white', cursor: 'pointer' }} />} />
        </div>
      </div>
    </>
  )
}