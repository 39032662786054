import './styles.css'
import logo from '../../assets/logo.png'
export const Footer: React.FC = () => {
  return (
    <footer className="row justify-content-between align-items-center border-top">
      <div className="col-md-12 d-flex align-items-center">
        <a href="www.cachacagestor.com.br" className="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1">
          <img src={logo} alt="logo" style={{ width: '40px', marginRight: '10px' }}  />
        </a>
        <span className="mb-3 mb-md-0 text-muted" style={{fontSize: '12px'}}>Todos os direitos reservados ao Cachaça Gestor Ltda © {new Date().getFullYear()}</span>
      </div>
    
    </footer>
  )
}