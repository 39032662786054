import { Loading } from 'components/loading/loading'
import React, { useEffect } from 'react'
import { getInvoice } from 'services/Signature/Signature'

export const TableSignature: React.FC<{ summary: any, useCheckBox: any, viewInvoice: boolean, companySelected: any }> = ({ ...props }) => {
  const { summary, useCheckBox, viewInvoice, companySelected } = props
  const [loadingCells, setLoadingCells] = React.useState<{ [key: string]: boolean }>({})
  const [reloadTable, setReloadTable] = React.useState(false)
  const [invoicePdf, setInvoicePdf] = React.useState<string>('')

  useEffect(() => {
    if (invoicePdf) {
      window.open(invoicePdf, '_blank')
    }
  }, [reloadTable, invoicePdf])

  const statusOfPayment = (installment: any) => {
    const currentDate: any = new Date()
    currentDate.setHours(0, 0, 0, 0)

    if (installment.status === true) {
      return 'PAGO'
    } else if (currentDate > new Date(installment.data)) {
      return 'INADIMPLENTE'
    } else {
      return 'AGUARDANDO'
    }
  }

  const handleInvoice = async (installment: any, rowIndex: number) => {
    const parcela = { objectId: installment }
    try {
      setLoadingCells(prevLoadingCells => ({
        ...prevLoadingCells,
        [rowIndex]: true
      }))
      const response = await getInvoice({ fabrica: companySelected, parcela })

      setInvoicePdf(response.boleto.link)
      setReloadTable(!reloadTable)
      alert('Boleto gerado com sucesso')
    } catch (err: any) {
      alert('Erro ao gerar boleto!')
    }
    setLoadingCells(prevLoadingCells => ({
      ...prevLoadingCells,
      [rowIndex]: false
    }))
  }

  return (
    <>
      {summary.map((summaryItem: any, index: number) => (
        <div className='col-12' key={index}>
          <div className='subscription-info'>
            {useCheckBox.view && <div className="form-check my-1">
              <input
                className="form-check-input"
                type="radio"
                name={`radioSummary-${index}`}
                id={`radioSummary-${index}`}
                onChange={() => { useCheckBox.setButtonDisabled(false); useCheckBox.setSignatureSelected(summaryItem) }}
              />
              <label className="form-check-label" htmlFor={`radioSummary-${index}`}>
                Selecionar Assinatura
              </label>
            </div>}
            <p id={`summary-name-${index+1}`}><strong>Nome:</strong> {summaryItem.nome}</p>
            <p id={`summary-plan-${index+1}`}><strong>Plano:</strong> {summaryItem.plano}</p>
            <p id={`summary-owner-${index+1}`}><strong>Proprietário:</strong> {summaryItem.proprietario}</p>
            <p id={`summary-payment-${index+1}`}><strong>Forma de Pagamento:</strong> {summaryItem.formaPagamento}</p>
          </div>

          {summaryItem.parcelas.length > 0 ? (

            <div className='col-12'>
              <table className='table'>
                <thead>
                  <tr>
                    <th scope="col">Data</th>
                    <th scope="col">Parcela</th>
                    <th scope="col">Valor</th>
                    <th scope="col">Status</th>
                    {viewInvoice && summaryItem.formaPagamento === 'BOLETO' && <th scope="col" id={`invoice_${index+1}`}>Boleto</th>}
                  </tr>
                </thead>
                <tbody>
                  {summaryItem.parcelas.map((installment: any, indexInstallment: any) => (
                    <tr id={`installment_${indexInstallment}`} key={indexInstallment}>
                      <td
                        id={`date_${index + 1}_${indexInstallment + 1}`}
                        key={`date_${index + 1}_${indexInstallment + 1}`}
                      >
                        {new Date(installment.data).toLocaleDateString('pt-BR')}
                      </td>
                      <td
                        id={`installment_${index + 1}_${indexInstallment + 1}`}
                        key={`installment_${index + 1}_${indexInstallment + 1}`}
                      >
                        {installment.parcela}
                      </td>
                      <td
                        id={`value_${index + 1}_${indexInstallment + 1}`}
                        key={`value_${index + 1}_${indexInstallment + 1}`}
                      >
                        {`R$ ${installment.valor.toFixed(2).replace('.', ',')}`}
                      </td>
                      <td
                        className={`${installment.status ? 'pago' : 'aguardando'}`}
                      >
                        {statusOfPayment(installment) === 'INADIMPLENTE' ?
                          (
                            <span
                              id={`status_${index + 1}_${indexInstallment + 1}`}
                              key={`status_${index + 1}_${indexInstallment + 1}`}
                              style={{ color: 'red' }}>INADIMPLENTE
                            </span>
                          )
                          : (
                            <span
                              id={`status_${index + 1}_${indexInstallment + 1}`}
                              key={`status_${index + 1}_${indexInstallment + 1}`}
                            >
                              {statusOfPayment(installment)
                              }
                            </span>
                          )}
                      </td>
                      {viewInvoice && summaryItem.formaPagamento === 'BOLETO' && <td>
                        {loadingCells[indexInstallment] ? (
                          <Loading />
                        ) : (
                          <>
                            {installment.boleto ? (
                              <a
                                id={`view_invoice_${index + 1}_${indexInstallment + 1}`}
                                key={`view_invoice_${index + 1}_${indexInstallment + 1}`}
                                href={installment.boleto}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Visualizar Boleto
                              </a>
                            ) : (
                              <a
                                id={`download_invoice_${index + 1}_${indexInstallment + 1}`}
                                key={`download_invoice_${index + 1}_${indexInstallment + 1}`}
                                href="#"
                                onClick={(e) => {
                                  e.preventDefault()
                                  handleInvoice(installment.objectId, indexInstallment)
                                }}
                              >
                                Baixar
                              </a>
                            )}
                          </>
                        )}
                      </td>}

                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className='col-12'>
              <p className='text-center h3' id='summary-no-installments'>Nenhuma parcela encontrada</p>
            </div>
          )}
        </div>
      ))}
    </>
  )
}