import { useState } from 'react'
import { TextWithSearch } from '../../components/inputs/text/TextWithSearch'
import { updateSuperUser } from '../../services/SuperUser/super-user'
import { Loading } from '../../components/loading/loading'
import { Buttom } from '../../components/buttom/buttom'
import { getCompanies } from '../../services/Signature/Signature'
import { SelectField } from '../../components/inputs/select/Select'
export const SuperUser = () => {
  const [loading, setLoading] = useState(false)
  const [showOptions, setShowCompanies] = useState<boolean>(false)
  const [companies, setCompanies] = useState<object[]>([])
  const [userSelected, setUserSelected] = useState<Record<string, string>>({})
  const [state, setState] = useState({ nome: '' })
  const [isButtonDisabled, setButtonDisabled] = useState(true)
  const [companySelected, setCompanySelected] = useState<any>({})

  const superUsers = [
    { label: 'Aline', value: 'Aline', },
    { label: 'Luiz', value: 'Luiz' },
    { label: 'Gabriel', value: 'Gabriel' },
    { label: 'Edmo', value: 'Edmo' },
    { label: 'Tiago', value: 'Tiago' }
  ]

  const handleSearch = async () => {
    setLoading(true)
    try {
      setButtonDisabled(true)
      // get companies
      const response = await getCompanies({ nome: state['nome'] })
      setCompanies(response)
      setShowCompanies(true)
      setLoading(false)
    } catch (error: any) {
      setLoading(false)
      setButtonDisabled(false)
      alert(error.response.data.message || 'Algum erro aconteceu!')
    }
  }

  const handleUpdateSuperUser = async () => {
    try {
      const response = await updateSuperUser(companySelected, userSelected['input-select-users'])
      alert(response.data.message)
    } catch (error: any) {
      alert(error.response.data.message || 'Algum erro aconteceu!')
    }
    setShowCompanies(false)
  }



  return (
    <div >
      <div className='justify-content-center d-flex mb-4 mt-3'>
        <SelectField label='Escolha um super usuário' options={superUsers} id='input-select-users' setState={setUserSelected} state={userSelected} name='input-select-users' />
      </div>
      <div className='justify-content-center d-flex'>
        <TextWithSearch id='input-text-signature' type="text" label="Digite a fábrica que deseja ativar" required={true} name="nome" state={state} setState={setState} handleSearch={handleSearch} />
      </div>
      {loading ? <Loading /> :
        <>
          <div className="justify-content-center  mt-3" style={{ overflow: 'hidden' }}>
            {
              showOptions &&
              (companies.map((item: any, index: number) => (
                <div key={`key-div-${index}`} className="form-check">
                  <input
                    key={`key-input-${index}`}
                    className="form-check-input text-primary"
                    type="radio"
                    name="options-inline"
                    id={`option-${index}`}
                    value={`option-${index}`}
                    onChange={() => { setButtonDisabled(false); setCompanySelected(item) }}
                  />
                  <label key={`key-label-${index}`} className="form-check-label" htmlFor={`option-${index}`}>
                    <strong>Nome: </strong>{item.empresa.nome} - <strong>Data de Criação: </strong>{new Date(item.empresa.createdAt).toLocaleDateString('pt-BR')}
                  </label> <br />
                </div>
              )))}
          </div>

          <br />
          {showOptions &&
            <div className="row justify-content-center my-">
              <Buttom text="Selecionar" id='btn-select' onClick={() => { handleUpdateSuperUser() }} disabled={isButtonDisabled} />
            </div>
          }</>}
    </div >
  )
}