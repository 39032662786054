import React, { useEffect, useState } from 'react'
import { getCompanies, getSummary, updatePlan } from '../../../../services/Signature/Signature'
import './styles.css'
import { ButtomWithIcon } from '../../../../components/buttom/buttomWithIcon'
import { IoMdArrowRoundBack } from 'react-icons/io'
import { Buttom } from '../../../../components/buttom/buttom'
import { Loading } from '../../../../components/loading/loading'
import { SelectField } from '../../../../components/inputs/select/Select'
import { TableSignature } from 'components/table/TableSignature'

export const UpdatePlanOfSignature: React.FC<{ companySelected: any, setUpdatePlan: any, setSubscriptionMenu: any, setViewSubscription: any, setCompanySelected: any }> = ({ ...props }) => {
  const { companySelected, setSubscriptionMenu, setViewSubscription, setCompanySelected, setUpdatePlan } = props
  const [loading, setLoading] = useState(true)
  const [summary, setSummary] = useState<any>([])
  const [signatureSelected, setSignatureSelected] = useState<any>({})
  const [isButtonDisabled, setButtonDisabled] = useState(true)
  const [plan, setPlan] = useState({ plan: '' })
  const [openSelectToChoice, setOpenSelectToChoice] = useState(false)

  useEffect(() => {
    const handleSummary = async () => {
      try {
        setLoading(true)
        const response = await getSummary(companySelected)
        if (response.length === 0) {
          setSummary([{
            nome: companySelected.empresa.nome,
            plano: companySelected.plano.nome,
            proprietario: companySelected.proprietario.nome,
            formaPagamento: '',
            parcelas: []
          }])
        } else {
          setSummary(response)
        }
        setLoading(false)
      } catch (error: any) {
        alert(error.response.data.message || 'Algum erro aconteceu!')
      }
    }
    handleSummary()
  }, [])

  const handleUpdatePlan = async () => {
    const request = {
      fabrica: companySelected,
      plano: plan.plan,
      assinatura: signatureSelected
    }

    try {
      const response = await updatePlan(request)
      alert(response.message)
      const companyUpdated = await getCompanies({ nome: companySelected.empresa.nome })
      setCompanySelected(companyUpdated[0])
      setUpdatePlan(false)
      setSubscriptionMenu(true)
    } catch (error: any) {
      alert(error.response.data.message || 'Algum erro aconteceu!')
    }
  }

  return (
    <>
      {loading ? <Loading /> :
        openSelectToChoice == false && <>
          {summary.length > 0 && <div className='col-12'>
            <h1 className='text-center'>Selecione uma assinatura para alterar </h1>
          </div>}
          <div className='row'>
            <TableSignature summary={summary} useCheckBox={{ view: true, setButtonDisabled, setSignatureSelected }} viewInvoice={false} companySelected={companySelected}/>
            <div className='col-12 mt-3 justify-content-center d-flex'>
              <Buttom text="Selecionar" id='btn-select-new-plan' onClick={() => { setSignatureSelected(signatureSelected); setOpenSelectToChoice(true) }} disabled={isButtonDisabled} />
            </div>
          </div>
        </>
      }
      {openSelectToChoice && <div className='row'>
        <div className='col-12 justify-content-center d-flex mt-3'>
          <h2>Alterar plano</h2>
        </div>
        <div className='col-12 justify-content-center d-flex'>
          <span>A assinatura selecionada está no plano <strong> {signatureSelected.plano} </strong></span>
        </div>
        <div className='col-12 mt-3 justify-content-center d-flex'>
          <SelectField label="Selecione o novo plano" options={[{ label: 'Ouro', value: 'OURO' }, { label: 'Prata', value: 'PRATA' }]} id="new-plan" setState={setPlan} state={plan} name="plan" />
        </div>
        <div className='col-12 mt-3 justify-content-center d-flex'>
          <Buttom text="Alterar" id='btn-update-plan' onClick={handleUpdatePlan} disabled={isButtonDisabled} />
        </div>
        <div className='col-12 mt-5 justify-content-center d-flex'>
          <ButtomWithIcon
            text="Selecionar outra assinatura"
            onClick={() => { setOpenSelectToChoice(false); setButtonDisabled(true) }}
            id="button-select-new-subscription"
            disabled={false}
            icon={<IoMdArrowRoundBack size="20" style={{ color: 'white', cursor: 'pointer' }} />}
          />
        </div>
      </div>}
      {!openSelectToChoice && <div className='col-12 mt-5 justify-content-center d-flex'>
        <ButtomWithIcon
          text="Selecionar outra opção"
          onClick={() => { setUpdatePlan(false); setViewSubscription(false); setSubscriptionMenu(true) }}
          id="button-select-new-subscription"
          disabled={false}
          icon={<IoMdArrowRoundBack size="20" style={{ color: 'white', cursor: 'pointer' }} />}
        />
      </div>}

    </>

  )

}