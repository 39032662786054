import * as React from 'react'
import Button from '@mui/material/Button'
import './styles.css'
export const ButtomWithIcon: React.FC<{ text: string, onClick?: () => void, id: string, disabled?: boolean, icon: any }> = ({ ...props }) => {
  const { text, id, icon } = props
  return (
    <Button id={id}
      className="buttom-with-icon"
      onClick={props.onClick}
      variant="contained"
      style={{ width: '300px', opacity: props.disabled ? 0.6 : 1, color: 'white' }}
      size='medium'
      disabled={props.disabled}
    >
      <div className='row justify-content-center'>
        <div className='col-2'>
          {icon}
        </div>
        <div className='col-10'>
          <span style={{ fontSize: '12px' }}>{text}</span>
        </div>
      </div>
    </Button>
  )
}
