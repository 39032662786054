import { Card } from '../../../components/card/card'
import { IoMdArrowRoundBack } from 'react-icons/io'
import { ButtomWithIcon } from '../../../components/buttom/buttomWithIcon'
import { useState } from 'react'
import { SubscriptionSummary } from './Options/SubscriptionSummary'
import { CreateSignature } from './Options/CreateSignature'
import { MarkSignatureAsPaid } from './Options/MarkSignatureAsPaid'
import { UpdatePlanOfSignature } from './Options/UpdatePlanOfSignature'
import { CancelSignature } from './Options/CancelSignature'
import { CancelAccount } from './Options/CancelAccount'
import { UpdateSignature } from './Options/UpdateSignature'
import { UpdateDueDate } from './Options/UpdateDueDate'

export const SubscriptionMenu: React.FC<{ companySelected: any, setSubscriptionControl: any, setCompanySelected: any }> = ({ ...props }) => {
  const { companySelected, setSubscriptionControl, setCompanySelected } = props
  const [viewSubscription, setViewSubscription] = useState(false)
  const [subscriptionMenu, setSubscriptionMenu] = useState(true)
  const [createSubscription, setCreateSubscription] = useState(false)
  const [cancelSignature, setCancelSignature] = useState(false)
  const [markAsPaid, setMarkAsPaid] = useState(false)
  const [updatedPlan, setUpdatedPlan] = useState(false)
  const [cancelAccount, setCancelAccount] = useState(false)
  const [updateSignature, setUpdateSignature] = useState(false)
  const [updateDueDate, setUpdateDueDate] = useState(false)

  return (
    <>
      {subscriptionMenu && <><div className='align-items-center justify-content-center'>
        <div className='row'>
          <div className='col-12'>
            <h1 className='text-center'>Controle de assinaturas</h1>
          </div>
        </div>
        <div className='row justify-content-center '>
          <strong>Fabrica selecionada:&nbsp;</strong> {companySelected.empresa.nome}
        </div>
      </div>
        <div className='row m-1 justify-content-center' style={{ marginBottom: '50px' }}>
          <div className='col-sm-12 col-md-3 justify-content-center d-flex' onClick={() => { setCreateSubscription(true); setSubscriptionMenu(false) }}>
            <Card name="Criar assinatura" tooltip="Criar nova assinatura de 12 meses para a fábrica selecionada" id={'card-create-subscription'} />
          </div>
          <div className='col-sm-12 col-md-3 justify-content-center d-flex' onClick={() => { setViewSubscription(true); setSubscriptionMenu(false) }}>
            <Card name="Ver assinatura" tooltip="Ver situação das parcelas da assinatura" id={'card-view-subscription'} />
          </div>
          <div className='col-sm-12 col-md-3 justify-content-center d-flex' onClick={() => { setUpdateSignature(true); setSubscriptionMenu(false) }}>
            <Card name="Atualizar assinatura" tooltip="Atualizar assinatura para a fábrica selecionada" id={'card-update-signature'} />
          </div>
          <div className='col-sm-12 col-md-3 justify-content-center d-flex' onClick={() => { setMarkAsPaid(true); setSubscriptionMenu(false) }}>
            <Card name="Marcar parcela como paga" tooltip="Marcar parcela de uma assinatura como paga" id={'card-view-mark-as-paid'} />
          </div>
          <div className='col-sm-12 col-md-3 justify-content-center d-flex' onClick={() => { setUpdatedPlan(true); setSubscriptionMenu(false) }}>
            <Card name="Alterar plano da assinatura" tooltip="Alterar plano da assinatura" id={'card-update-plan'} />
          </div>
          <div className='col-sm-12 col-md-3 justify-content-center d-flex' onClick={() => { setCancelSignature(true); setSubscriptionMenu(false) }}>
            <Card name="Cancelar assinatura" tooltip="Cancelar assinatura" id={'card-cancel-signature'} />
          </div>
          <div className='col-sm-12 col-md-3 justify-content-center d-flex' onClick={() => { setCancelAccount(true); setSubscriptionMenu(false) }}>
            <Card name="Cancelar Conta" tooltip="Cancelar conta de uma fábrica selecionada" id={'card-cancel-account'} />
          </div>
          <div className='col-sm-12 col-md-3 justify-content-center d-flex' onClick={() => { setUpdateDueDate(true); setSubscriptionMenu(false) }}>
            <Card name='Alterar vencimento' tooltip='Alterar vencimento de alguma parcela da fábrica selecionada' id={'card-updated-date-subscription'} />
          </div>

          {/*<div className='col-sm-12 col-md-3 justify-content-center d-flex'>
          {/*
           <div className='col-sm-12 col-md-3 justify-content-center d-flex'>
            <Card name="Cancelar assinatura" tooltip="Cancelar assinatura da fábrica selecionada" id={"card-cancel-subscription"} />
          </div>
          {/*<div className='col-sm-12 col-md-3 justify-content-center d-flex'>
            <Card name="Cancelar cobranças" tooltip="Cancelar cobranças da fábrica selecionada" id={"card-cancel-payment-subscription"} />
          </div>
          <div className='col-sm-12 col-md-3 justify-content-center d-flex'>
            <Card name="Alterar valor da cobrança" tooltip="Altera valor de cobrança da fábrica selecionada" id={"card-updated-price-subscription"} />
          </div>
           */}

          <div className='col-12 mt-3 justify-content-center d-flex' >
            <ButtomWithIcon text="Selecionar outra fabrica" onClick={() => setSubscriptionControl(false)} id={'button-select-new-subscription'} disabled={false}
              icon={<IoMdArrowRoundBack size="20" style={{ color: 'white', cursor: 'pointer' }} />} />
          </div>
        </div>
      </>}
      {(viewSubscription && !subscriptionMenu) && <SubscriptionSummary companySelected={companySelected} setViewSubscription={setViewSubscription} setSubscriptionMenu={setSubscriptionMenu} />}
      {(createSubscription && !subscriptionMenu) && <CreateSignature companySelected={companySelected} setCreateSubscription={setCreateSubscription} setSubscriptionMenu={setSubscriptionMenu} />}
      {(markAsPaid && !subscriptionMenu) && <MarkSignatureAsPaid setViewSubscription={setViewSubscription} companySelected={companySelected} setMarkAsPaid={setMarkAsPaid} setSubscriptionMenu={setSubscriptionMenu} />}
      {(updatedPlan && !subscriptionMenu) && <UpdatePlanOfSignature setViewSubscription={setViewSubscription} companySelected={companySelected} setUpdatePlan={setUpdatedPlan} setSubscriptionMenu={setSubscriptionMenu} setCompanySelected={setCompanySelected} />}
      {(cancelSignature && !subscriptionMenu) && <CancelSignature setViewSubscription={setViewSubscription} companySelected={companySelected} setCancelSignature={setCancelSignature} setSubscriptionMenu={setSubscriptionMenu} setCompanySelected={setCompanySelected} />}
      {(cancelAccount && !subscriptionMenu) && <CancelAccount setViewSubscription={setViewSubscription} companySelected={companySelected} setCancelAccount={setCancelAccount} setSubscriptionMenu={setSubscriptionMenu} />}
      {(updateSignature && !subscriptionMenu) && <UpdateSignature companySelected={companySelected} setUpdateSignature={setUpdateSignature} setSubscriptionMenu={setSubscriptionMenu} />}
      {(updateDueDate && !subscriptionMenu) && <UpdateDueDate companySelected={companySelected} setUpdateDueDate={setCreateSubscription} setSubscriptionMenu={setSubscriptionMenu} />}
    </>

  )
}