import api from '../api/index'

export const getContingency = async (params: object) => {
  const response = await api.get('/admin/estadoscontingencia', { params }).then((response) => {
    return response.data
  }).catch((error) => {
    ErrorUnknown(error)
  })
  return response
}

export const updateContingency = async (params: string[]) => {
  const response = await api.post('/admin/estadoscontingencia', params)
    .then((response) => {
      return response.data
    }).catch((error) => {
      ErrorUnknown(error)
    })
  return response
}

const ErrorUnknown = (error: any) => {
  throw {
    response: {
      data: {
        message: error.message === 'Network Error' ?
          'Erro inesperado com requisição de contingência! Entre em contato com a equipe de desenvolvimento.'
          : error.response.data.message
      }
    }
  }
}