import React, { useEffect, useState } from 'react'
import { getSummary, updateSummary } from '../../../../services/Signature/Signature'
import './styles.css'
import { ButtomWithIcon } from '../../../../components/buttom/buttomWithIcon'
import { IoMdArrowRoundBack } from 'react-icons/io'
import { Buttom } from '../../../../components/buttom/buttom'
import { Loading } from '../../../../components/loading/loading'

export const MarkSignatureAsPaid: React.FC<{ companySelected: any, setMarkAsPaid: any, setSubscriptionMenu: any, setViewSubscription: any }> = ({ ...props }) => {
  const { companySelected, setSubscriptionMenu, setViewSubscription, setMarkAsPaid } = props
  const [loading, setLoading] = useState(true)
  const [loadingButton, setLoadingButton] = useState(false)
  const [summary, setSummary] = useState<any>([])
  const [isButtonDisabled, setButtonDisabled] = useState(true)
  const [installmentStep, setInstallmentStep] = useState<any>(false)
  const [selectedSummaryIndex, setSelectedSummaryIndex] = React.useState(-1)
  const [monthsToUpdate, setMonthsToUpdate] = React.useState<any>([])
  const [reloadTable, setReloadTable] = useState(false)


  const handleCheckboxChange = (index: number, obj: any) => {
    
    const monthIndex = monthsToUpdate.findIndex((month: any) => month === obj)
    if (monthIndex !== -1) {
      const updatedMonthsToUpdate = [...monthsToUpdate]
      updatedMonthsToUpdate.splice(monthIndex, 1)
      setMonthsToUpdate(updatedMonthsToUpdate)
    } else {
      setMonthsToUpdate([...monthsToUpdate, obj])
    }
  }

  const submitUpdatedSubscription = async () => {
    try {
      setLoadingButton(true)
      let monthName: string
      const months: any = []
      for (const month of monthsToUpdate) {
        monthName = String(new Date(month.data).getMonth() + 1).padStart(2, '0')
        months.push(monthName)

      }
      await updateSummary({ fabrica: companySelected, meses: months, objectId: summary[selectedSummaryIndex].objectId })
      alert('Assinatura atualizada!')
      setMonthsToUpdate([])
      setReloadTable(!reloadTable)
      setLoadingButton(false)

    } catch (error: any) {
      alert(error || 'Algum erro aconteceu!')
      setLoadingButton(false)
    }
  }


  useEffect(() => {
    const handleSummary = async () => {
      try {
        setLoading(true)
        const response = await getSummary(companySelected)
        if (response.length === 0) {
          setSummary([{
            nome: companySelected.empresa.nome,
            plano: companySelected.plano.nome,
            proprietario: companySelected.proprietario.nome,
            formaPagamento: '',
            parcelas: []
          }])
        } else {
          setSummary(response)
        }
        setLoading(false)
      } catch (error: any) {
        alert(error.response?.data.message || 'Algum erro aconteceu!')
      }
    }

    handleSummary()
  }, [reloadTable])

  const statusOfPayment = (installment: any) => {
    const currentDate = new Date()
    if (installment.status === true) {
      return 'PAGO'
    } else if (installment.status === false && currentDate > new Date(installment.data)) {
      return 'INADIMPLENTE'
    } else {
      return 'AGUARDANDO'
    }
  }

  const handleRadioChange = (index: any) => {
    setButtonDisabled(false)
    setSelectedSummaryIndex(index)
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : (

        <>

          {!installmentStep &&
            <>
              {loading ? (
                <Loading />
              ) : (
                <>
                  <div className='row'>
                    <div className='col-12'>
                      <h1 className='text-center'>Selecione a assinatura para alterar as parcelas</h1>
                    </div>

                    {
                      summary.map((summaryItem: any, index: number) => (
                        <div className='col-12' key={index}>
                          <div className='subscription-info'>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name={`radioSummary_${index}`}
                                id={`radioSummary_${index}`}
                                checked={selectedSummaryIndex === index}
                                onChange={() => handleRadioChange(index)}
                              />
                              <label className="form-check-label" htmlFor={`radioSummary_${index}`}>
                                Selecionar Assinatura
                              </label>
                            </div>
                            <p id='summary-name'><strong>Nome:</strong> {summaryItem.nome}</p>
                            <p id='summary-plan'><strong>Plano:</strong> {summaryItem.plano}</p>
                            <p id='summary-owner'><strong>Proprietário:</strong> {summaryItem.proprietario}</p>
                            <p id='summary-payment'><strong>Forma de Pagamento:</strong> {summaryItem.formaPagamento}</p>
                          </div>

                          {summaryItem.parcelas.length > 0 ? (

                            <div className='col-12'>
                              <table className='table'>
                                <thead>
                                  <tr>
                                    <th scope="col">Data</th>
                                    <th scope="col">Parcela</th>
                                    <th scope="col">Valor</th>
                                    <th scope="col">Status</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {summaryItem.parcelas.map((installment: any, indexInstallment: any) => (
                                    <tr id={`installment_${index}`} key={index}>
                                      <td
                                        id={`date_${index + 1}_${indexInstallment + 1}`}
                                        key={`date_${index + 1}_${indexInstallment + 1}`}
                                      >
                                        {new Date(installment.data).toLocaleDateString('pt-BR')}
                                      </td>
                                      <td
                                        id={`installment_${index + 1}_${indexInstallment + 1}`}
                                        key={`installment_${index + 1}_${indexInstallment + 1}`}
                                      >
                                        {installment.parcela}
                                      </td>
                                      <td
                                        id={`value_${index + 1}_${indexInstallment + 1}`}
                                        key={`value_${index + 1}_${indexInstallment + 1}`}
                                      >
                                        {`R$ ${installment.valor.toFixed(2).replace('.', ',')}`}
                                      </td>
                                      <td
                                        className={`${installment.status ? 'pago' : 'aguardando'}`}
                                      >
                                        {statusOfPayment(installment) === 'INADIMPLENTE' ?
                                          (
                                            <span
                                              id={`status_${index + 1}_${indexInstallment + 1}`}
                                              key={`status_${index + 1}_${indexInstallment + 1}`}
                                              style={{ color: 'red' }}>INADIMPLENTE
                                            </span>
                                          )
                                          : (
                                            <span
                                              id={`status_${index + 1}_${indexInstallment + 1}`}
                                              key={`status_${index + 1}_${indexInstallment + 1}`}
                                            >
                                              {statusOfPayment(installment)
                                              }
                                            </span>
                                          )}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          ) : (
                            <div className='col-12'>
                              <p className='text-center h3' id='summary-no-installments'>Nenhuma parcela encontrada</p>
                            </div>
                          )}
                        </div>

                      ))}
                  </div>


                  <div className="row justify-content-center my-">
                    <Buttom text="Selecionar" id='btn-select' onClick={() => { setInstallmentStep(true) }} disabled={isButtonDisabled} />
                  </div>
                </>
              )}
            </>

          }

          {installmentStep && <>


            <div className='row'>
              <div className='col-12'>
                <div className='subscription-info'>
                  <p><strong>Nome:</strong> {summary[selectedSummaryIndex].nome}</p>
                  <p><strong>Plano:</strong> {summary[selectedSummaryIndex].plano}</p>
                  <p><strong>Proprietário:</strong> {summary[selectedSummaryIndex].proprietario}</p>
                  <p><strong>Forma de Pagamento:</strong> {summary[selectedSummaryIndex].formaPagamento}</p>
                </div>
              </div>

              <div className='col-12'>
                <table className='table'>
                  <thead>
                    <tr>
                      <th scope='col'>Marcar como paga</th>
                      <th scope="col">Data</th>
                      <th scope="col">Parcela</th>
                      <th scope="col">Valor</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {summary[selectedSummaryIndex].parcelas.map((installment: any, indexInstallment: number) => (
                      <tr id={`parcela_${indexInstallment}`} key={`key-tr-${indexInstallment}`}>

                        <td>
                          <div className="form-check">
                            {installment.status !== true ? (
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                name={`radioSignature_${indexInstallment}`}
                                id={`radioSignature_${indexInstallment}`}

                                onChange={() => handleCheckboxChange(indexInstallment, installment)}
                              />
                            ) : null}
                          </div>
                          <div className="form-check">
                          </div>
                        </td>

                        <td
                        id={`date_${indexInstallment + 1}`}
                        key={`date_${indexInstallment + 1}`}
                      >
                        {new Date(installment.data).toLocaleDateString('pt-BR')}
                      </td>
                      <td
                        id={`installment_${indexInstallment + 1}`}
                        key={`installment_${indexInstallment + 1}`}
                      >
                        {installment.parcela}
                      </td>
                      <td
                        id={`value_${indexInstallment + 1}`}
                        key={`value_${indexInstallment + 1}`}
                      >
                        {`R$ ${installment.valor.toFixed(2).replace('.', ',')}`}
                      </td>
                      <td
                        className={`${installment.status ? 'pago' : 'aguardando'}`}
                      >
                        {statusOfPayment(installment) === 'INADIMPLENTE' ?
                          (
                            <span
                              id={`status_${indexInstallment + 1}`}
                              key={`status_${indexInstallment + 1}`}
                              style={{ color: 'red' }}>INADIMPLENTE
                            </span>
                          )
                          : (
                            <span
                              id={`status_${indexInstallment + 1}`}
                              key={`status_${indexInstallment + 1}`}
                            >
                              {statusOfPayment(installment)
                              }
                            </span>
                          )}
                      </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            { loadingButton ? (
                <Loading />
                ) : (
            <div className="row justify-content-center my-">
              <Buttom text="Marcar como pago" disabled={false} id='btn-select' onClick={() => { submitUpdatedSubscription() }} />
            </div>)}

          </>}

          <div className='col-12 mt-3 justify-content-center d-flex'>
            <ButtomWithIcon
              text="Selecionar outra opção"
              onClick={() => { setMarkAsPaid(false); setViewSubscription(false); setSubscriptionMenu(true) }}

              id="button-select-new-subscription"
              disabled={false}
              icon={<IoMdArrowRoundBack size="20" style={{ color: 'white', cursor: 'pointer' }} />}
            />
          </div>


        </>
      )}
    </>
  )
}