import React, { useEffect, useState } from 'react'
import { cancelAccount } from '../../../../services/Signature/Signature'
import { Buttom } from '../../../../components/buttom/buttom'
import { ButtomWithIcon } from 'components/buttom/buttomWithIcon'
import { IoMdArrowRoundBack } from 'react-icons/io'
import { Text } from 'components/inputs/text/Text'
import './styles.css'
import { useNavigate } from 'react-router-dom'

export const CancelAccount: React.FC<{ companySelected: any, setCancelAccount: any, setSubscriptionMenu: any, setViewSubscription: any }> = ({ ...props }) => {
  const { companySelected, setSubscriptionMenu, setViewSubscription, setCancelAccount } = props
  const [isButtonDisabled, setButtonDisabled] = useState(true)
  const [loadingButton, setLoadingButton] = useState(false)
  const navigate = useNavigate()
  const [state, setState] = useState({
    confirm: ''
  })

  useEffect(() => {
    if (state.confirm.length > 0 && state.confirm === 'CANCELAR') {
      setButtonDisabled(false)
    } else {
      setButtonDisabled(true)
    }
  }, [state])

  const handleCancelAccount = async () => {
    setLoadingButton(true)
    const request = {
      fabrica: companySelected,
      confirmacao: state.confirm
    }

    try {
      const response = await cancelAccount(request)
      alert(response.message)
      setCancelAccount(false)
      setSubscriptionMenu(true)
      navigate('/dashboard')
    } catch (error: any) {
      alert(error.response.data.message || 'Algum erro aconteceu!')
    }
    setLoadingButton(false)
  }

  return (
    <>
      <h1 className='text-center'>Cancelar conta </h1>
      <h4 className='text-center'>Esta operação irá <span className='text-danger'>CANCELAR</span> a assinatura da fábrica {companySelected.empresa.nome} e <br /><span className='text-danger'>RETIRAR</span> o acesso do sistema</h4>
      <div className='row'>
        <div className='col-12 mt-5 justify-content-center d-flex'>
          <Text
            id='input-confirm-operation'
            label='Digite CANCELAR para confirmar'
            required={true}
            state={state}
            name='confirm'
            type='text'
            setState={setState} />
        </div>
        <div className='col-12 mt-3 justify-content-center d-flex'>
          <Buttom text="Cancelar" id='btn-cancel-account' onClick={handleCancelAccount} disabled={isButtonDisabled} loadingButton={loadingButton} />
        </div>
        <div className='col-12 mt-5 justify-content-center d-flex'>
          <ButtomWithIcon
            text="Selecionar outra opção"
            onClick={() => { setCancelAccount(false); setViewSubscription(false); setSubscriptionMenu(true) }}
            id="button-select-new-subscription"
            disabled={false}
            icon={<IoMdArrowRoundBack size="20" style={{ color: 'white', cursor: 'pointer' }} />}
          />
        </div>
      </div>
    </>
  )

}