import * as React from 'react'
import Button from '@mui/material/Button'
import './styles.css'
import { Loading } from '../loading/loading'
export const Buttom: React.FC<{ text: string, onClick?: () => void, id: string, disabled?: boolean, loadingButton?: boolean, color?: string }> = ({ ...props }) => {
  const { text, id, loadingButton, disabled, onClick, color } = props
  return (
    <Button id={id}
      className="buttom"
      onClick={!loadingButton ? onClick : void 0}
      variant="contained"
      style={{ width: '300px', opacity: disabled ? 0.6 : 1, backgroundColor: color ? color : '#332E1A' }}
      size='medium'
      disabled={disabled}
    >
      {loadingButton ? <Loading /> : text}</Button >
  )
}
